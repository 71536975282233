import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { integrations } from 'data/integrations';
import Link from 'components/ui/link';
import Paragraph from 'components/ui/Paragraph';
import * as Styled from './styled';

const WorksWith = () => {
  return (
    <Styled.Section>
      <div className="container">
        <Styled.Integrations>
          <Styled.Content>
            <h2>
              Connect LiveSession with <br /> <span>your tool stack</span>
            </h2>
            <Paragraph size="xl">Use LiveSession with your favourite apps.</Paragraph>
            <Link href="/integrations/" withArrow>
              See all integrations
            </Link>
          </Styled.Content>
          <Styled.CustomCardsContainer>
            {integrations.map(({ img, alt, link, title }) => (
              <Styled.CustomCardIntegration
                key={alt}
                className={['item', alt === 'LiveChat' ? 'active' : null].join(' ')}
                to={link || null}
                as={GatsbyLink}
              >
                <img src={img} alt={alt} className="img-fluid" />
                <p>{title || alt}</p>
              </Styled.CustomCardIntegration>
            ))}
          </Styled.CustomCardsContainer>
        </Styled.Integrations>
      </div>
    </Styled.Section>
  );
};
export default WorksWith;
