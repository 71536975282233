import styled from 'styled-components';
import { media } from 'theme/breakpoints';

export const SignupBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;
  margin-top: 40px;
  & form {
    justify-content: flex-start;
  }
  .checkmarks {
    margin: 20px 0 0;
    display: grid;
    grid-template-columns: repeat(3, max-content);
    grid-column-gap: 53px;
    ${media.tablet} {
      grid-column-gap: 18px;
    }
    p {
      margin-bottom: 0;
      display: flex;
      font-size: 14px;
      position: relative;
      text-align: center;
      line-height: normal;
      & span {
        margin-left: 4px;
        ${media.mobile} {
          display: none;
        }
      }
      &::after {
        content: '';
        width: 5px;
        height: 5px;
        border-radius: 50%;
        position: absolute;
        background-color: #000;
        top: 50%;
        right: -29px;
        transform: translateY(-50%);
        ${media.tablet} {
          right: -11px;
        }
      }
      &:nth-child(3) {
        &::after {
          display: none;
        }
      }
    }
  }
  ${media.tablet} {
    & form {
      justify-content: center;
    }
  }
`;
